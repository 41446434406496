import React from 'react'
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs'
import Container from '../elements/Container'
import Content from '../elements/Content'
import Layout from '../components/Layout'
import Header from '../components/Header'

export default ({ data }) => {
  // Define the Blog Home & Blog Post content returned from Prismic
  const doc = data.prismic.allImprints.edges.slice(0,1).pop();
  if (!doc) return null;

  return(
    <Layout>
      <Header title='Impressum' imageUrl='https://images.prismic.io/reiseamseln/25e845ae-476f-4c70-a97f-99deb405763f_cover1.jpg?auto=compress,format'></Header>
      <Container type="article">
        <Content>
          {RichText.render(doc.node.content)}
        </Content>
      </Container>
    </Layout>
  )
}

export const query = graphql`
{
  prismic{
    allImprints {
      edges {
        node {
          content
        }
      }
    }
  }
}
`
