import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding: 3rem 0 1rem 0;
  font-family: ${props => props.theme.fontFamily.body};
  p,
  li {
    letter-spacing: -0.003em;
    --baseline-multiplier: 0.179;
    --x-height-multiplier: 0.35;
    font-size: 1.15rem;
    line-height: 1.58;
    code {
      padding: 0.2rem 0.5rem;
      margin: 0.5rem 0;
    }
  }
  a:not(.gatsby-resp-image-link) {
    color: black;
    box-shadow: inset 0 -2px 0 ${props => props.theme.tint.blue};
    border-bottom: 1px solid ${props => props.theme.tint.blue};
    transition: ${props => props.theme.transitions.default.transition};
    text-decoration: none;
    &:hover,
    &:focus {
      background: ${props => props.theme.tint.blue};
      color: black;
    }
  }
  h1 {
    margin-top: 2rem;
  }
  h2 {
    margin-top: 1rem;
  }
  .block-img {
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    text-align: center;
    img {
      border-radius: ${props => props.theme.borderRadius.default};
      box-shadow: ${props => props.theme.shadow.image};
      max-width: 100%;
    }
  }
  [data-oembed-type='video'] {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  h2,
  h3,
  h4 {
    position: relative;
  }
  h2 .anchor,
  h3 .anchor,
  h4 .anchor {
    display: inline-block;
    position: absolute;
    left: -2rem;
    box-shadow: none;
    border-bottom: none;
    color: ${props => props.theme.tint.blue};
    visibility: hidden;
  }
  h2:hover .anchor,
  h3:hover .anchor,
  h4:hover .anchor {
    visibility: visible;
  }
  h2 .anchor:hover,
  h3 .anchor:hover,
  h4 .anchor:hover {
    color: ${props => props.theme.colors.primary.base};
    background: none;
  }
`

const Content = ({ children }) => {
  return <Wrapper>{children}</Wrapper>
}

export default Content

Content.propTypes = {
  children: PropTypes.node.isRequired,
}